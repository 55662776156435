import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateMetaAccounts, updateMetaAuthHappening, updateMetaCurrentUid, updateMetaDisabled, updateMetaText } from "../store/schemas/authManagementSlice";
import MetaMaskOnboarding from '@metamask/onboarding';
import { useNavigate } from "react-router";
import { isStaked, manageNetwork } from "../httpcalls/contractThunk";
import { ClientOnly } from "../../library/client/serverCallHandler";
import { logout } from "../httpcalls/adminThunk";


const MetamaskBtn = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userActive = useSelector(state => state.auth.userActive)
  const user_login = useSelector(state => state.authManagement.accounts[0])
  const uid = useSelector(state => state.authManagement.uid)


  const buttonText = useSelector(state => state.authManagement.metaMaskText)
  const isDisabled = useSelector(state => state.authManagement.isDisabled)
  const accounts = useSelector(state => state.authManagement.accounts)
  const authHappening = useSelector(state => state.authManagement.authHappening)
  const [unique_component, setUniqueComponent] = useState(null)
  const current_component = useSelector(state => state.authManagement.uid)
  const onboarding = useRef();
  const network = useSelector(state => state.authManagement.network)


  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
      setUniqueComponent(Math.random().toString())
    }
  }, []);

  useEffect(() => {
    if (userActive == true) {
      dispatch(logout())
    }
  }, [accounts])


  useEffect(() => {
    function handleNewAccounts(newAccounts) {
      dispatch(updateMetaAccounts(newAccounts))
      if (newAccounts.length == 0) {
        dispatch(updateMetaDisabled(false))
      }
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {

      window.ethereum.on('accountsChanged', handleNewAccounts);
      window.ethereum.request({ method: 'eth_accounts' }).then((accounts) => {
        dispatch(updateMetaAccounts((accounts == null ? [] : accounts)))
      })


      return () => {
        setTimeout(() => {
          window.ethereum.removeListener('accountsChanged', handleNewAccounts);
        }, 200)


      };
    }
  }, []);


  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {

        dispatch(updateMetaText(accounts[0].substring(0, 10)))


      } else {
        dispatch(updateMetaText("Connect With Metamask"))
      }
    }
  }, [accounts]);


  const loginWithMeta = () => {
    try {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        if (isDisabled == false) {
          dispatch(updateMetaDisabled(true))

          window.ethereum
            .request({ method: 'eth_requestAccounts' })
            .then((newAccounts) => {
              dispatch(updateMetaCurrentUid(unique_component));
              dispatch(updateMetaAccounts(newAccounts))
              if (network == false) {
                dispatch(manageNetwork())
              }
            })
            .catch(res => {
              dispatch(updateMetaDisabled(false))
            });
        }
      } else {
        onboarding.current.startOnboarding();
      }
    } catch (error) {
      console.log(error)
    }

  };


  useEffect(() => {
    if (authHappening != false) {

      if (accounts.length > 0) {
        dispatch(manageNetwork())
      }
      else {
        loginWithMeta()
      }
      dispatch(updateMetaAuthHappening(false))
    }
  }, [authHappening])

  const signOut = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_requestPermissions",
        params: [
          {
            eth_accounts: {}
          }
        ]
      });
    } catch (error) {
      console.log(error)
    }

  }
  return (

    <ClientOnly >

      {() => (



        (user_login != undefined) ?

          <button className='metamask-btn  vivify fadeIn duration-300 delay-800'>
            <div className='side-button'>
              <div className='side-button'>

                <label onClick={() => { signOut() }}>Manage Accounts<span>{
                  user_login.substring(0, 7) + "..." +
                  user_login.substring(user_login.length - 5)
                }</span></label>


              </div>
            </div>
          </button>

          :


          <button className={'metamask-btn ' + props.class} onClick={() => { loginWithMeta() }}><img src="/assets/metamaskfox.svg" /><span className="mainText">{buttonText}</span><span className="mobileText">Sign In</span></button>

      )
      }
    </ClientOnly>


  );

}

export default MetamaskBtn;