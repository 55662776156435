import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from './schemas/authSlice';
import proofSlice from './schemas/proofSlice';
import notificationSlice from './schemas/notificationSlice';
import stakingSlice from './schemas/stakingSlice';
import authManagementSlice from './schemas/authManagementSlice';
import newsSlice from './schemas/newsSlice';
import demoSlice from './schemas/demoSlice';
import loadingSlice from './schemas/loadingSlice';
import chainSlice from './schemas/chainSlice';
import networkSlice from './schemas/networkSlice';

export const createReduxStore = (preloadedState) => {
  return configureStore({
    reducer: {
      authManagement: authManagementSlice,
      auth: authSlice,
      mainapp: combineReducers({
        proofSlice: proofSlice,
        notificationSlice: notificationSlice,
        stakingSlice: stakingSlice,
        newsSlice: newsSlice,
        demoSlice: demoSlice,
        loadingSlice: loadingSlice,
        chainSlice: chainSlice,
        networkSlice: networkSlice,
        authSlice: authSlice

      })

    },
    preloadedState
  });
};

// For client-side: 
