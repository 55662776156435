import { pluriAbi, usdtPolygonAbi, pluriPolygonFaucetAbi, pluriPolygonStakeholderAbi, dnsAbi } from './abi'
import { useSelector } from 'react-redux'
import { ethers, Contract } from "ethers";
import { createAsyncThunk } from '@reduxjs/toolkit'





const getContractForMetamask = async (token) => {
    let functions = {}
    const abi = token.abi
    const address = token.address

    if (window.ethereum) {
        const provider = new ethers.BrowserProvider(window.ethereum)
        const signer = await provider.getSigner()
        const contract = new Contract(address, abi, signer)

        functions = { provider, contract }
    }
    const rpcProvider = new ethers.JsonRpcProvider(token.rpc)
    const rpcContract = new Contract(address, abi, rpcProvider);
    return { ...functions, rpcProvider, rpcContract }
}

const getNativeTokenFunctionsMetamask = async ({ decimalPlace, rpc }) => {
    let functions = {}
    if (window.ethereum) {
        const provider = new ethers.BrowserProvider(window.ethereum)
        functions = { provider }
    }
    const rpcProvider = new ethers.JsonRpcProvider(rpc)
    return { ...functions, rpcProvider }
}


const generateTokenStructure = async ({ address, abi, decimalPlace, rpc }) => {
    const token = {
        address: address,
        abi: abi,
        decimalPlace,
        rpc: rpc
    };

    return {
        token,
        ... await getContractForMetamask(token)
    };
}



export let getNetworkTokens = {}


export const setNetworkTokens = createAsyncThunk(
    'web3/network',
    async (data, thunkApi) => {
    const state = thunkApi.getState()
    const chains = state.mainapp.chainSlice
    if (state.mainapp.authSlice.metamask.accountId) {
        for (let chain of chains.chainList) {
            getNetworkTokens[chain.slug] = {
                pluri: await generateTokenStructure({ address: chain.scan.coin.address, abi: pluriAbi, decimalPlace: 18, rpc: chain.rpcUrl }),
                faucet: await generateTokenStructure({ address: chain.scan.faucet.address, abi: pluriPolygonFaucetAbi, decimalPlace: 18, rpc: chain.rpcUrl }),
                stakeholder: await generateTokenStructure({ address: chain.scan.stakeholder.address, abi: pluriPolygonStakeholderAbi, decimalPlace: 18, rpc: chain.rpcUrl }),
                nativeTokenFunctions: await getNativeTokenFunctionsMetamask({ decimalPlace: 18, rpc: chain.rpcUrl }),
            }

        }
        return true
    }
    return false

})


export const setNetworkTokensReturnLocal = createAsyncThunk(
    'web3/network',
    async (data, thunkApi) => {
    const state = thunkApi.getState()
    const chains = state.mainapp.chainSlice
    let localInstance = {}
    if (state.mainapp.authSlice.metamask.accountId) {
        for (let chain of chains.chainList) {
            localInstance[chain.slug] = {
                pluri: await generateTokenStructure({ address: chain.scan.coin.address, abi: pluriAbi, decimalPlace: 18, rpc: chain.rpcUrl }),
                faucet: await generateTokenStructure({ address: chain.scan.faucet.address, abi: pluriPolygonFaucetAbi, decimalPlace: 18, rpc: chain.rpcUrl }),
                stakeholder: await generateTokenStructure({ address: chain.scan.stakeholder.address, abi: pluriPolygonStakeholderAbi, decimalPlace: 18, rpc: chain.rpcUrl }),
                nativeTokenFunctions: await getNativeTokenFunctionsMetamask({ decimalPlace: 18, rpc: chain.rpcUrl }),
            }

        }
    }
    return localInstance
})


