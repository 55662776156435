import React from 'react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider, Text } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { StaticRouter } from "react-router-dom/server";

import './css/App.css';

import '@mantine/core/styles.css';
import './css/vivify.min.css';
import '@mantine/notifications/styles.css';
import './css/dash.css'
import './css/auth.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '@mantine/tiptap/styles.css';



const Render = ({ url }) => {
  if (typeof window == 'undefined') {
    return (
      <MantineProvider withGlobalStyles withNormalizeCSS >
        <StaticRouter location={url}>
          <Notifications />
          <App />
        </StaticRouter>
      </MantineProvider>
    )
  }
  else {
    return (
      <MantineProvider withGlobalStyles withNormalizeCSS >
        <BrowserRouter>
          <Notifications />
          <App />
        </BrowserRouter>
      </MantineProvider>
    )
  }



}


export default Render