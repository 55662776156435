import React, { useRef, useEffect, useState } from 'react';
import MetamaskBtn from './metamask';
import { NavLink, Link } from 'react-router-dom';
import { Menu } from '@mantine/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { Popover, Text, Button } from '@mantine/core';
import SwapNetwork from '../auth/swapNetwork';
import { useSelector } from 'react-redux';
import { OnboardingButton } from '../auth/authBtn';
import NetworkSwapBtn from '../auth/networkSwapBtn';


const Navbar = () => {
    const navigate = useNavigate();

    const [hasScrolled, setHasScrolled] = useState(false);
    const [opened, setOpened] = useState(false);
    const [noticeShown, setNoticeShown] = useState(false);
    const location = useLocation();
    const [visibleBanner, setVisibleBanner] = useState(true);
    const currentSelectedChain = useSelector(state => state.mainapp.chainSlice.currentChain);

    const coin = useSelector(state => state.mainapp.chainSlice.currentChain.scan.coin);
    const faucet = useSelector(state => state.mainapp.chainSlice.currentChain.scan.faucet);
    const stakeholder = useSelector(state => state.mainapp.chainSlice.currentChain.scan.stakeholder);

    useEffect(() => {

        function handleScroll() {
            const threshold = 0; // Set your threshold here
            if (window.pageYOffset > threshold) {
                setHasScrolled(true);

            } else {
                setHasScrolled(false);

            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const pagesVisableOn = ['/trydemo'];
    let originBlacklist = ['eth.pluricoin.io']

    useEffect(() => {
        const checkIfPageVisible = () => {
            return pagesVisableOn.includes(location.pathname);
        };
        if (originBlacklist.includes(process.env.REACT_APP_ORIGIN)) {
            setNoticeShown(false);
        }
        else {
            if (checkIfPageVisible()) {
                setNoticeShown(true);
            } else {
                setNoticeShown(false);
            }
        }


    }, [location]);



    return (
        <>

            {/* {
                (noticeShown && visibleBanner) ? <div className='notice-banner'>
                    <div className='notice-banner-text'>
                        <p>Promotion: 0 Pluri Required To Post, For The First 2 Weeks Of Launch</p>
                        <button onClick={
                            () => {
                                setVisibleBanner(false);
                            }
                        }>
                            <i className='material-icons-outlined'>close</i>
                        </button>
                    </div>
                </div> : null
            } */}

            <nav className={
                hasScrolled ? 'nav-bar scrolled' : 'nav-bar'
            }>

                <div className='nav-left'>
                    <img onClick={
                        () => {
                            navigate('/');
                        }
                    } src="/assets/plurilogo.png" alt='logo' />
                    <ul>
                        <li>
                            <Link to={'/'} replace={true} className={({ isActive }) => isActive ? "focused-nav activity-button" : "activity-button"}>Home</Link>
                        </li>
                        <li>
                            <NavLink to={
                                currentSelectedChain.slug + '/faucet'
                            } replace={true} className={({ isActive }) => isActive ? "focused-nav activity-button" : "activity-button"}>Faucet</NavLink>
                        </li>
                        {/* <li>
                            <a onClick={
                                () => {
                                    handleAnimate()
                                    setTimeout(() => {
                                        navigate('/docs');
                                    }, 600);
                                }
                            }>Documentation</a>
                        </li> */}
                        <li>
                            <NavLink to={
                                currentSelectedChain.slug + '/stakeholders'

                            } replace={true} className={({ isActive }) => isActive ? "focused-nav activity-button" : "activity-button"}>Stakeholder</NavLink>
                        </li>

                        <li>
                            <NavLink to={
                                currentSelectedChain.slug + '/trydemo'
                            } replace={true} className={({ isActive }) => isActive ? "focused-nav activity-button" : "activity-button"}>Try Demo</NavLink>
                        </li>
                        {/*    <li>

                            <NavLink to="/articles" replace={true} className={({ isActive }) => isActive ? "focused-nav activity-button" : "activity-button"}>Newsletter</NavLink>
                        </li>
 */}
                        <li>
                            <NavLink to={
                                currentSelectedChain.slug + '/tokenomics'

                            } replace={true} className={({ isActive }) => isActive ? "focused-nav activity-button" : "activity-button"}>Tokenomics</NavLink>
                        </li>

                        <li>
                            <a href='https://pluri.gitbook.io/' target='_blank'>Docs</a>
                            <i className='material-icons-rounded'>open_in_new</i>
                        </li>


                        <Menu offset={22} position="bottom-end" styles={
                            {
                                dropdown: {
                                    backgroundColor: 'var(--frostedBackground)',
                                    outline: 'var(--frostedBorder2)',
                                    border: 'none',
                                    backdropFilter: 'blur(10px)',
                                },
                                item: {
                                    backgroundColor: 'transparent !important',
                                }

                            }
                        } shadow="md" width={200}>
                            <Menu.Target>
                                <li style={{ cursor: 'pointer' }}>
                                    <a>{
                                        currentSelectedChain.name
                                    } Scan</a>
                                    <i style={{ fontSize: '22px', marginLeft: '1px', marginTop: '0px' }} className='material-icons-rounded'>arrow_drop_down</i>
                                </li>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Label>
                                    {currentSelectedChain.name} Scan
                                </Menu.Label>
                                <NavLink to={
                                    coin.url
                                } target="_blank" >
                                    <Menu.Item rightSection={<i style={{ fontSize: '15px' }} className='material-icons-rounded'>open_in_new</i>}>
                                        {coin.name} Token
                                    </Menu.Item>
                                </NavLink>

                                <NavLink to={stakeholder.url}>
                                    <Menu.Item rightSection={<i style={{ fontSize: '15px' }} className='material-icons-rounded'>open_in_new</i>}>
                                        {stakeholder.name} Contract
                                    </Menu.Item>
                                </NavLink>

                                <NavLink to={faucet.url}>
                                    <Menu.Item rightSection={<i style={{ fontSize: '15px' }} className='material-icons-rounded'>open_in_new</i>}>
                                        {faucet.name} Contract
                                    </Menu.Item>
                                </NavLink>

                            </Menu.Dropdown>
                        </Menu>


                    </ul>
                </div>


                {/* <MetamaskBtn /> */}
                <NetworkSwapBtn inlineMode={false} />
            </nav>

            <nav className={hasScrolled
                ? 'mobile-nav scrolled-mobile' : 'mobile-nav'
            }>
                <img onClick={
                    () => {
                        navigate('/');
                    }
                } src="/assets/plurilogo.png" alt='logo' />


                <div className='right-mobile-nav'>
                    <MetamaskBtn />
                    <Menu styles={
                        {
                            dropdown: {
                                backgroundColor: 'var(--frostedBackground)',
                                outline: 'var(--frostedBorder2)',
                                border: 'none',
                                backdropFilter: 'blur(10px)',
                            },
                            item: {
                                backgroundColor: 'transparent !important',
                            }

                        }
                    } offset={25} position={"bottom-end"} opened={opened} onChange={setOpened}>
                        <Menu.Target>
                            <button className='menu-btn'><i className='material-icons-outlined'>menu</i></button>
                        </Menu.Target>
                        <Menu.Dropdown >
                            <Menu.Item onClick={
                                () => {
                                    navigate('/');
                                }
                            } icon={<i className='material-icons-outlined'>home</i>} >Home</Menu.Item>



                            <Menu.Item onClick={
                                () => {
                                    navigate(currentSelectedChain.slug + '/faucet');
                                }
                            } icon={<i className='material-icons-outlined'>toll</i>} >Faucet</Menu.Item>

                            <Menu.Item onClick={
                                () => {
                                    navigate(currentSelectedChain.slug + '/stakeholders');
                                }
                            } icon={<i className='material-icons-outlined'>handshake</i>} >Stakeholders</Menu.Item>

                            {/*    <Menu.Item onClick={
                                () => {
                                    navigate('/articles');
                                }
                            } icon={<i className='material-icons-outlined'>article</i>} >Newsletter</Menu.Item>
 */}
                            <Menu.Item onClick={
                                () => {
                                    navigate(currentSelectedChain.slug + '/trydemo');
                                }
                            } icon={<i className='material-icons-outlined'>article</i>} >Try Demo</Menu.Item>

                            <Menu.Item onClick={
                                () => {
                                    navigate(currentSelectedChain.slug + '/tokenomics');
                                }
                            } icon={<i className='material-icons-outlined'>monetization_on</i>} >Tokenomics</Menu.Item>

                            <Menu.Item onClick={
                                () => {
                                    window.open('https://pluri.gitbook.io/', '_blank');
                                }
                            } icon={<i className='material-icons'>code</i>}  >Documentation</Menu.Item>


                        </Menu.Dropdown>
                    </Menu>
                </div>

            </nav>
        </>
    );

}

export default Navbar;