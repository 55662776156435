import React, { useEffect } from "react";
import { Link, Outlet, Route } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import { useState } from "react";
import { useDispatch } from "react-redux";


import { useNavigate } from "react-router-dom";


import { alterLoading } from "../store/schemas/loadingSlice";
import { setRecheck, toggleAuthWindow } from "../store/schemas/authSlice";


function SwapNetwork() {
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const metamaskConnected = useSelector(state => state.mainapp.authSlice.metamask.connected)


    const currentSelectedChain = useSelector(state => state.mainapp.chainSlice.currentChain)
    const [selectedChain, setSelectedChain] = useState(currentSelectedChain.id || 137)
    const isOnChain = useSelector(state => state.mainapp.authSlice.metamask.isOnChain)

    const chainList = useSelector(state => state.mainapp.chainSlice.chainList);

    const expectedChainList = useSelector(state => state.mainapp.chainSlice.expectedChainList);




    const [networkAdded, setNetworkAdded] = useState(true);

    const addPolygonNetwork = async () => {
        const rpcUrl = chainList.find(chain => chain.id === selectedChain).rpcUrl
        const chainId = chainList.find(chain => chain.id === selectedChain).chainId
        const chainName = chainList.find(chain => chain.id === selectedChain).name
        const nativeCurrency = chainList.find(chain => chain.id === selectedChain).nativeCurrency
        try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                // set from selected network
                params: [{
                    chainId: '0x' + chainId.toString(16),
                    chainName: chainName,
                    nativeCurrency: nativeCurrency,
                    rpcUrls: [rpcUrl],

                }],


            });
            setNetworkAdded(true)

        } catch (error) {
            console.error("Failed to add network to MetaMask:", error);

        }
    };



    async function switchNetwork() {
        if (typeof window.ethereum !== 'undefined') {
            try {

                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x' + selectedChain.toString(16) }],
                });

                setNetworkAdded(true);
                dispatch(alterLoading({
                    loading: true,
                    statusText: 'Verifying Network...',
                    origin: 'Network Manager'

                }))
           
                dispatch(toggleAuthWindow())
                dispatch(setRecheck())
                setTimeout(() => {
                    dispatch(alterLoading({
                        loading: false,
                        statusText: 'Network Status Confirmed',
                        origin: 'Network Manager'
                    }))
                }, 1000);



            } catch (error) {
                if (error.code === 4902) {
                    console.log("Polygon network is not added to MetaMask.");
                    setNetworkAdded(false);
                } else {
                    console.error("Error switching to Polygon network:", error);
                }
            }
        } else {
            console.log("MetaMask is not available.");
        }
    }



    const getIcon = (chainId) => {
        switch (chainId) {
            case 137:
                return '/assets/chains/polygon.png'
            case 1:
                return '/assets/chains/ethereum.png'
            case 56:
                return '/assets/chains/binance.png'
            case 43114:
                return '/assets/chains/avalanche.png'
            case 61:
                return '/assets/chains/ethereumclassic.png'
            case 42161:
                return '/assets/chains/arbitrum.png'
            case 10:
                return '/assets/chains/optimism.png'
            case 250:
                return '/assets/chains/fantom.png'
            default:
                return '/assets/chains/polygon.png'
        }
    }



    return (
        <>
            {
                networkAdded ?
                    <div style={{
                        gridTemplateRows: '1fr ',
                        height: '430px'
                    }} className="auth-window vivify fadeIn duration-300 " >
                        {/* <div className="auth-header">
                        <div className='promo-action'>

                            <h1 style={{ marginBottom: 7 }}>
                                Wrong Network: Switch to Polygon
                            </h1>
                            <p>
                                Please switch MetaMask to Polygon network to continue.
                            </p>

                        </div>
                    </div> */}

                        <div className="close-btn-container">
                            <button onClick={() => dispatch(toggleAuthWindow())} className="close-btn">
                                <i className="material-icons">
                                    close
                                </i>
                            </button>
                        </div>

                        <div className="auth-header">

                            <div className='promo-action'>
                                <img src={
                                    getIcon(currentSelectedChain.id)
                                } alt="icon" />

                                <h1 style={{ marginBottom: 7 }}>
                                    Swap to {
                                        currentSelectedChain.name
                                    } Network
                                </h1>
                                <p>
                                    Click to swap {currentSelectedChain.name} network on MetaMask and use with Pluri {(currentSelectedChain.rpcUrl).toString()}
                                </p>

                            </div>
                        </div>

                        <div style={{
                            margin: 'auto',
                            marginBottom: 35

                        }}>
                            <button className="meta-btn" onClick={switchNetwork} >

                                Confirm MetaMask
                            </button>
                        </div>


                    </div> : <div className="auth-window " style={{
                        gridTemplateRows: '1fr ',
                        height: '430px'
                    }}>
                        <div className="auth-header">
                            <div className="floating-header">
                                <button className="auth-back-btn" onClick={() => {
                                    setNetworkAdded(true)
                                }}><i className="material-icons">arrow_back</i>
                                    <span>Back</span>
                                </button>
                            </div>
                            <div className='promo-action'>
                                <img src={
                                    getIcon(selectedChain)
                                } alt="icon" />

                                <h1 style={{ marginBottom: 7 }}>
                                    Add {
                                        chainList.find(chain => chain.id === selectedChain).name
                                    } Network
                                </h1>
                                <p>
                                    Click to add {chainList.find(chain => chain.id === selectedChain).name} network to MetaMask and use with Frigid {(chainList.find(chain => chain.id === selectedChain).rpcUrl).toString()}
                                </p>

                            </div>
                        </div>

                        <div style={{
                            margin: 'auto',
                            marginBottom: 35
                        }}>
                            <button className="meta-btn" onClick={addPolygonNetwork} >
                                <i className="material-icons">add_circle</i>

                                Add Network
                            </button>
                        </div>


                    </div>
            }
            <style>
                {
                    `
                    
.mantine-Popover-dropdown {
    border: none;
    background-color: transparent;
}
                    `
                }
            </style>
        </>


    );
}

export default SwapNetwork;

