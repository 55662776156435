import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNetworkTokens } from './helper';

const useEffectClient = (func, params = []) => {
  const dispatch = useDispatch();
  const [tokensReady, setTokensReady] = useState(false);
  const [chainReady, setChainReady] = useState(false);

  const accountId = useSelector(state => state.auth.metamask.accountId)
  const currentChain = useSelector(state => state.mainapp.chainSlice.currentChain)



  useEffect(() => {
    (async () => {
        if (accountId) {
            dispatch(setNetworkTokens()).then((res) => {
                setTokensReady(true);
            })
        }
        
    })()
  }, [accountId]);

  useEffect(() => {
    console.log(currentChain)
    if(currentChain.rpcUrl !== undefined){
      setChainReady(true);
    }
  }, [currentChain])

  useEffect(
    () => {
      if (tokensReady && chainReady) {
        func();
      }
    }, [tokensReady, currentChain, ...params])
};

export default useEffectClient;
