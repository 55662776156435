import React, { useEffect } from "react";
import { Link, Outlet, Route } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button } from '@mantine/core';

import { useNavigate } from "react-router-dom";


import MetaMaskOnboarding from "@metamask/onboarding";
import { OnboardingButton } from "./authBtn";
import SwapNetwork from "./swapNetwork";
import { toggleAuthWindow } from "../store/schemas/authSlice";

function Auth() {
    const dispatch = useDispatch()


    const navigate = useNavigate();
    const metamaskConnected = useSelector(state => state.mainapp.authSlice.metamask.connected);
    const isOnChain = useSelector(state => state.mainapp.authSlice.metamask.isOnChain);
    const chainMatch = useSelector(state => state.mainapp.authSlice.metamask.chainMatch);

    const [showAuthWindow, setShowAuthWindow] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowAuthWindow(!metamaskConnected);
        }, 100);

        return () => clearTimeout(timer);
    }, [metamaskConnected, isOnChain]);




    const [networkAdded, setNetworkAdded] = useState(true);

    const addPolygonNetwork = async () => {
        try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainId: '0x89',
                    chainName: 'Polygon',
                    nativeCurrency: {
                        name: 'MATIC',
                        symbol: 'MATIC',
                        decimals: 18
                    },
                    rpcUrls: ['https://polygon-rpc.com/'],

                }]
            });
            setNetworkAdded(true)

        } catch (error) {
            console.error("Failed to add Polygon network to MetaMask:", error);

        }
    };

    async function switchNetwork() {
        if (typeof window.ethereum !== 'undefined') {
            try {

                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x89' }],
                });
                console.log("Switched to Polygon network.");
                setNetworkAdded(true);
            } catch (error) {
                if (error.code === 4902) {
                    console.log("Polygon network is not added to MetaMask.");
                    setNetworkAdded(false);
                } else {
                    console.error("Error switching to Polygon network:", error);
                }
            }
        } else {
            console.log("MetaMask is not available.");
        }
    }


    useEffect(() => {
        console.log('metamaskConnected', metamaskConnected)
    }, [metamaskConnected])




    return (
        <>
            {showAuthWindow && (
                <div className="auth-container">

                    <div style={{



                        gridTemplateRows: '1fr',
                        height: '180px',

                    }} className="auth-window">

                        <div className="close-btn-container">
                            <button onClick={() => dispatch(toggleAuthWindow())} className="close-btn">
                                <i className="material-icons">
                                    close
                                </i>
                            </button>
                        </div>
                        <div className="meta-btn-container">

                            <OnboardingButton />
                        </div>
                    </div>
                </div>
            )}
            {(!showAuthWindow && metamaskConnected && !isOnChain) && (
                <div className="auth-container">

                    <SwapNetwork />
                </div>
            )}

            {!chainMatch && (
                <div className="auth-container">

                    <SwapNetwork />
                </div>
            )}

        </>


    );
}

export default Auth;

