import { createAsyncThunk } from '@reduxjs/toolkit';
import Web3 from 'web3';
import { isLogin, isNotLogin } from '../store/schemas/authSlice';
import { alterNotification } from '../store/schemas/notificationSlice';
import { startLoading, stopLoading } from '../store/schemas/loadingSlice'
const server = '/api/auth/';
const api = '/api/';


export const logout = createAsyncThunk(
    'auth/logout',
    async (data, thunkApi) => {
        try {

            const response = await fetch(`${server}?auth=false&model=auth&path=/logout`, {
                method: 'GET',
                credentials: 'include'
            });
            const result = await response.json();

            if ('error' in result) {
                throw result
            }
            thunkApi.dispatch(isNotLogin())

        } catch (error) {
            console.log(error.error, error.message)
        }
    }
);


export const getUser = createAsyncThunk(
    'auth/getUser',
    async (data, thunkApi) => {
        try {

            const response = await fetch(`${server}?auth=false&model=auth&path=/getUser`, {
                method: 'GET',
                credentials: 'include'
            });
            const result = await response.json();

            if ('error' in result) {
                throw result
            }
            thunkApi.dispatch(isLogin())

        } catch (error) {
            console.log(error.error, error.message)
        }
    }
);


export const loginAdmin = createAsyncThunk(
    'auth/loginAdmin',
    async (data, thunkApi) => {
        try {

            const response = await fetch(`${server}?auth=false&model=auth&path=/generateNonce`);
            const result = await response.json();
            const nonce = result.message;
            const web3 = new Web3(window.ethereum);
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const publicAddress = accounts[0];
            const signature = await web3.eth.personal.sign(nonce, publicAddress, '');
            const verifyResponse = await fetch(`${server}?auth=false&model=auth&path=/verifyNonce`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ signature, publicAddress })
            });

            const verifyResult = await verifyResponse.json();
            if ('error' in verifyResult) {
                thunkApi.dispatch(isNotLogin())
                throw verifyResult
            }
            thunkApi.dispatch(isLogin())
            return verifyResult;
        } catch (error) {
            alert(error.error, error.message)
        }
    }
);



export const postArticle = createAsyncThunk(
    'admin/postArticle',
    async (data, thunkApi) => {
        try {

            const response = await fetch(`${api}?auth=true&model=post&path=/postArticle`,
                {
                    method: 'POST',
                    body: data.formData
                });
            const result = await response.json();
            if ('error' in result) {
                throw result
            }
            alert(result.message)
            thunkApi.dispatch(stopLoading())

            return result;
        } catch (error) {
            alert(error.error, error.message)
        }
    }
);

