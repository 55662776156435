import React, { useRef, useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import { Menu } from '@mantine/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { Popover, Text, Button } from '@mantine/core';

import SwapNetworkSolo from './swapNetworkSolo';
import { useSelector } from 'react-redux';
import { OnboardingButton } from '../auth/authBtn';

const NetworkSwapBtn = ({ inlineMode }) => {
    const navigate = useNavigate();


    const currentSelectedChain = useSelector(state => state.mainapp.chainSlice.currentChain);
    const [swapNetwork, setSwapNetwork] = useState(false);
    const connected = useSelector(state => state.mainapp.authSlice.metamask.connected);

    const accountId = useSelector(state => state.mainapp.authSlice.metamask.accountId);

    useEffect(() => {
        setSwapNetwork(false)
    }, [currentSelectedChain])




    return (
        <>



            <div className={
                inlineMode ? 'vivify fadeIn duration-300 delay-1000 ' : 'vivify fadeIn duration-300 delay-300 inline-mode'
            }>
                {
                    connected ? <Popover position="bottom" withArrow shadow="md" opened={
                        swapNetwork
                    } onChange={setSwapNetwork}>
                        <Popover.Target>
                            <button onClick={
                                () => {
                                    setSwapNetwork(!swapNetwork)
                                }

                            } className="toggle-network">
                                <img src={'/assets/chains/' + currentSelectedChain.image} alt="icon" />
                                <h3>
                                    <p style={{
                                        marginBottom: -2,
                                        fontSize: 10,
                                        marginTop: 0
                                    }}>Swap Network</p>
                                    <span>{currentSelectedChain.name} Network</span>
                                    <p>
                                        {
                                            accountId && accountId.slice(0, 6) + '...' + accountId.slice(accountId.length - 4, accountId.length)
                                        }
                                    </p>
                                </h3>
                                <i style={{
                                    opacity: 1,
                                    marginLeft: 7
                                }} className="material-icons">keyboard_arrow_down</i>
                            </button>
                        </Popover.Target>
                        <Popover.Dropdown>

                            <div>
                                <SwapNetworkSolo />

                            </div>
                        </Popover.Dropdown>
                    </Popover>
                        : <OnboardingButton />
                }
            </div>

            <style>
                {
                    inlineMode && `
                    .mantine-Popover-dropdown {
                        margin-left: 0px
                    }
                    .inline-mode {
                     display: none
                    }
                    .toggle-network {
                        background-color: transparent;
                        border: var(--authFrostedBorder)
                    }
                    .toggle-network:hover {
                        background-color: var(--frostedBackgroundSolid)
                    }
        
        `
                }
            </style>


        </>
    );

}

export default NetworkSwapBtn;