import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    metaMaskText: "Download Metamask",
    isDisabled:false,
    accounts:[],
    authHappening:false,
    uid:"",
    network:null
})


const authManagementSlice = createSlice({
    name:'authManagementSlice',
    initialState:initialState(),
    reducers: {
        resetAuth: state => initialState(),
        updateMetaText:(state, action)=> {
          state.metaMaskText = action.payload
        },
        updateMetaDisabled:(state, action)=> {
            state.isDisabled = action.payload
        },
        updateMetaAccounts:(state, action)=> {
            state.accounts = action.payload
        },
        updateMetaAuthHappening:(state, action)=> {
            state.authHappening = action.payload
        },
        updateMetaCurrentUid: (state, action)=> {
            state.uid = action.payload
        },
        updateNetwork: (state, action) => {
            state.network = action.payload
        }

    }
})

export const {updateMetaText, updateNetwork, updateMetaDisabled, updateMetaAccounts, updateMetaAuthHappening, updateMetaCurrentUid} = authManagementSlice.actions

export default authManagementSlice.reducer