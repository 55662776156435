import { useDispatch, useSelector } from "react-redux";
import useEffectClient from "./helpers/networkHook";
import { useState } from "react";
import { getNetworkTokens } from "./helpers/helper";
import { startLoading, stopLoading } from "./store/schemas/loadingSlice";
import { alterNotification } from "./store/schemas/notificationSlice";
import JSONBig from 'json-bigint';
import {ethers, formatUnits, parseUnits} from 'ethers'

const AutoStake = () => {
    const dispatch = useDispatch()
    const account = useSelector(state => state.auth.metamask.accountId)
    const chainList = useSelector(state => state.mainapp.chainSlice.chainList)
    const activeChain = useSelector(state => state.mainapp.chainSlice.currentChain)
    const [status, setStatus] = useState(null)
    const stake = async () => {
        try {
          for(let currentChain of chainList){
            const {stakeholder, pluri} = getNetworkTokens[currentChain.slug]
            let networkSwap = true
            while(networkSwap){
                try {
                    setStatus({account,  network:currentChain.name, requirement:"Switch To Network"})

                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: '0x' + currentChain.chainId.toString(16) }],
                    });
                    networkSwap = false

                } catch (error) {
                    if(error.code === 4902){
                        setStatus({account,  network:currentChain.name, requirement:"Add Network"})

                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            // set from selected network
                            params: [{
                                chainId: '0x' + currentChain.chainId.toString(16),
                                chainName: currentChain.name,
                                nativeCurrency: currentChain.nativeCurrency,
                                rpcUrls: [currentChain.rpcUrl],
            
                            }],
            
            
                        });
                    }
                }
            }
            
          

            
            let info = await stakeholder.rpcContract.stakeholdersInfo(account)
            let user_stake = JSONBig.parse(JSONBig.stringify(info))
            user_stake.stakedAt = user_stake[0]
            user_stake.index = user_stake[1]
            user_stake.earnings = user_stake[2]

            if (user_stake[0] == 0n) {
                try {
                    const allowance = await pluri.contract.allowance(account, stakeholder.token.address)
                    setStatus({account, allowance, network:currentChain.name, requirement:null})
                    if (allowance != 21000000000000000000000n) {
                        setStatus({account, allowance, network:currentChain.name, requirement:'Give allowance to stakeholder contract'})
                      const tx = await  pluri.contract.approve(stakeholder.token.address, 21000000000000000000000n)
                      dispatch(startLoading({ title: 'Processing Transaction', description: 'Please wait for the blockchain to approve your transaction' }))
              
                      const f = await tx.wait()
                      await new Promise(resolve => setTimeout(resolve, 3000)); // Wait for 5 seconds
                      dispatch(stopLoading())
                    }
                    setStatus({account, allowance:21000000000000000000000n, network:currentChain.name, requirement:'To stake'})
        
                    const newTx = await stakeholder.contract.stake()
                    dispatch(startLoading({ title: 'Processing Transaction', description: 'Please wait for the blockchain to approve your transaction' }))
                    await newTx.wait()
                    await new Promise(resolve => setTimeout(resolve, 3000)); 
                    dispatch(stopLoading())
                    setStatus({account, allowance:21000000000000000000000n, network:currentChain.name, requirement:'Staked'})
                    await new Promise(resolve => setTimeout(resolve, 2000)); 
    
                } catch (error) {
                    dispatch(stopLoading())
                    dispatch(alterNotification({ position: 'bottom-left', alertStyle: 'error', messageTitle: "Staking Error", message: error.reason, visible: true })) 
                }
               

            }
            else{
                setStatus({account, requirement:'Already Staked'})

            }
            
        }
          
        
        } catch (error) {
            console.log(error)
            dispatch(stopLoading())
            dispatch(alterNotification({ position: 'bottom-left', alertStyle: 'error', messageTitle: "Staking Error", message: error.reason, visible: true }))
        }
        
        
    }

    
    useEffectClient(() => {
        return
    }, [account])

    return (
        <div>
            <button style={{"background":"black","border":"none","padding":"20px","margin":"10px"}} onClick={stake}>Stake</button>
            <h5>Status</h5>
            <p>{JSONBig.stringify(status)}</p>

        </div>

        
    );
}

export default AutoStake;