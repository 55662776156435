import React from "react";
import { Link, Outlet, Route } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import { useState } from "react";
import { useDispatch } from "react-redux";

import { useDisclosure } from '@mantine/hooks';
import Auth from "./authWindow";
import SwapNetwork from "./swapNetwork";

import { useEffect } from "react";

import { setMetamaskConnected, setMetaText, setAccountId } from "../store/schemas/authSlice";
import { useNavigate } from "react-router-dom";

import { setPermissionState } from "../store/schemas/authSlice";
import { setOnChain } from "../store/schemas/authSlice";
import { formatUnits } from "ethers";
import MetaMaskOnboarding from "@metamask/onboarding";
import { setCurrentActiveChain } from "../store/schemas/chainSlice";

import { Popover } from "@mantine/core";
import { alterLoading } from "../store/schemas/loadingSlice";
import { setChainMatch } from "../store/schemas/authSlice";


function AuthBridge() {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const [opened, { open, close }] = useDisclosure(false);
    const toggleModal = () => opened ? close() : open();
    const navigate = useNavigate();

    const chainList = useSelector(state => state.mainapp.chainSlice.chainList);
    const currentSelectedChain = useSelector(state => state.mainapp.chainSlice.currentChain);

    const ONBOARD_TEXT = "Click here to install MetaMask!";
    const CONNECT_TEXT = "Connect";
    const CONNECTED_TEXT = "Connected";

    const [account, setAccount] = useState(null);
    const usdtAddress = '0xc2132D05D31c914a87C6611C10748AEb04B58e8F';
    const pluriAddress = '0xe469e49A15cF9c6B0C2685027eBb8bE43363bE33'
    const [maticBalance, setMaticBalance] = useState('0');
    const [usdtBalance, setUsdtBalance] = useState('0');
    const [pluriBalance, setPluriBalance] = useState('0');
    const isOnChain = useSelector(state => state.mainapp.authSlice.metamask.isOnChain)
    const [currentChain, setCurrentChain] = useState(null);
    const [swapNetwork, setSwapNetwork] = useState(false);
    const accountId = useSelector(state => state.mainapp.authSlice.metamask.accountId);
    const reCheck = useSelector(state => state.mainapp.authSlice.metamask.reCheck);

    const contractsLoaded = useSelector(state => state.mainapp.chainSlice.contractsLoaded);

    const [darkMode, setDarkMode] = useState(true);
    {
        /* Get MATIC balance */
    }

    const [buttonText, setButtonText] = React.useState(ONBOARD_TEXT);
    const [isDisabled, setDisabled] = React.useState(false);
    const [accounts, setAccounts] = React.useState([]);
    const onboarding = React.useRef();


    useEffect(() => {
        if (!onboarding.current) {
            onboarding.current = new MetaMaskOnboarding();
        }
    }, []);

    useEffect(() => {
        if (MetaMaskOnboarding.isMetaMaskInstalled()) {
            checkChain();
            if (accounts.length > 0) {
                setButtonText(CONNECTED_TEXT);
                setDisabled(true);
                onboarding.current.stopOnboarding();
                dispatch(setMetamaskConnected(true));

            } else {
                setButtonText(CONNECT_TEXT);
                setDisabled(false);
                dispatch(setMetamaskConnected(false));
            }
        }
    }, [accounts]);

    const checkNetworks = async () => {
        let chainId = await window.ethereum.request({
            "method": "eth_chainId",
            "params": []
          });

        if(window?.ethereum?.chainId == undefined){
            chainId = chainId;
        }

        setCurrentChain(chainId);

        const onChain = () => {
            for (let i = 0; i < chainList.length; i++) {
                if (chainList[i].id == parseInt(chainId, 16).toString(10)) {
                    dispatch(setOnChain(true));
                    return
                }
            }
            dispatch(setOnChain(false));
        }
        onChain()


        if (window.location.href.includes(
            chainList && chainList.find(chain => chain.id == parseInt(chainId, 16).toString(10))?.slug
        )) {
            dispatch(setChainMatch(true));
        }
        else {
            dispatch(setChainMatch(false));
        }
        
    }


    const checkChain = async () => {
        dispatch(setOnChain(false));
        checkNetworks();
    }


    useEffect(() => {
        const checkMetaMaskConnection = async () => {
            dispatch(setPermissionState(true));
            if (window.ethereum) {
                try {
                    const permissions = await window.ethereum.request({ method: 'wallet_getPermissions' });
                    const hasPermissions = permissions.some(permission => permission.parentCapability === 'eth_accounts');
                    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
                    
                    if (hasPermissions) {
                        // const accounts = await window.ethereum.request({ method: 'eth_accounts' });
                        dispatch(setMetamaskConnected(accounts.length > 0));
                        setAccounts(accounts);
                        if (accounts.length > 0) {
                            setAccount(accounts[0]);
                            dispatch(setAccountId(accounts[0]));
                        }
                        else{
                            setAccount(null);
                            dispatch(setAccountId(null));
                        }
                    } else {
                        dispatch(setMetamaskConnected(false));
                        dispatch(setPermissionState(false));
                        if (accounts.length > 0) {
                            setAccount(accounts[0]);
                            dispatch(setAccountId(accounts[0]));
                        }
                        else{
                            setAccount(null);
                            dispatch(setAccountId(null));
                        }
                    }
                    await checkChain();
                } catch (error) {
                    dispatch(setMetaText('Error Occurred Refresh Page'));
                    dispatch(setMetamaskConnected(false));
                }
            } else {
                dispatch(setMetaText('Download MetaMask'));
                dispatch(setMetamaskConnected(false));
            }
            setLoading(false);
        };

        checkMetaMaskConnection();

        const handleAccountsChanged = (newAccounts) => {
            setAccounts(newAccounts);
            checkMetaMaskConnection();
        };

        if (MetaMaskOnboarding.isMetaMaskInstalled()) {
            window.ethereum.on('accountsChanged', handleAccountsChanged);
            window.ethereum.on("chainChanged", checkChain);
            return () => {
                window.ethereum.removeListener('chainChanged', checkChain);
                window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
            };
        }
    }, [reCheck]);

    const onClick = () => {
        if (MetaMaskOnboarding.isMetaMaskInstalled()) {
            window.ethereum.request({ method: "eth_requestAccounts" }).then(handleAccountsChanged);
        } else {
            onboarding.current.startOnboarding();
        }
    };

    useEffect(() => {
        setSwapNetwork(false);
    }, [currentSelectedChain]);

    return (
        <>




        </>


    );
}

export default AuthBridge

