import { createSlice } from "@reduxjs/toolkit";



const initialState = () => ({
    loadingState: false,
    loadingTitle: 'Processing',
    loadingDescription: 'Please wait while we process your transaction',

    loadingData: {
        loading: null,
        statusText: 'Processing Transaction...',
        origin: 'Domain Purchase',
        progressBar: true
    },
    progressBar: {
        value: 0,
        max: 100
    }

});

const loadingSlice = createSlice({
    name: 'Loading Manager',
    initialState: initialState(),
    reducers: {
        startLoading: (state, action) => {
            state.loadingState = true;
            state.loadingTitle = action.payload.title;
            state.loadingDescription = action.payload.description;
        },
        stopLoading: state => {
            state.loadingState = false;
            state.loadingText = '';
        },
        alterLoading: (state, action) => {
            state.loadingData = action.payload
        }
    }
})

export const {
    startLoading,
    stopLoading,
    alterLoading


} = loadingSlice.actions

export default loadingSlice.reducer