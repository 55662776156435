import { createSlice } from "@reduxjs/toolkit";


const initialState = () => ({
    networkActive: false,
})

const networkSlice = createSlice({
    name: 'Network Manager',
    initialState: initialState(),
    reducers: {
        resetNetworkSlice: state => initialState(),
        toggleNetworkActive: state => {
            state.networkActive = !state.networkActive
        },

    }
})

export const {
    resetNetworkSlice,
    toggleNetworkActive
} = networkSlice.actions



export default networkSlice.reducer