import {createSlice} from "@reduxjs/toolkit";

{
    /*
       
        alertStyle: can be 'warning', 'error', 'success', 'info', 'help'
        messageTitle: title of the notification
        message: message of the notification
        visible: boolean, true means the notification is visible, false means it is not visible


        Create Open Notification
        dispatch(alterNotification( {alertStyle: "success",  messageTitle: 'Auto Place Complete', message: 'Your points have been randomly placed in their slots. You can confirm and continue to matchmaking', visible: true} )) ;

        Close Notification
        dispatch(alterNotification( {visible: false} )) ;
    */
}

const initialState = () => ({
  payments : [
    {
        id: 1,
        address: "0x757...16a0",
        amount: 0.0001,
        timestamp: "2021-09-01 12:00:00",
    },
    {
        id: 2,
        address: "0x757...16a0",
        amount: 0.0001,
        timestamp: "2021-09-01 12:00:00",
    },
    {
        id: 3,
        address: "0x757...16a0",
        amount: 0.0001,
        timestamp: "2021-09-01 12:00:00",
    },
    {
        id: 4,
        address: "0x757...16a0",
        amount: 0.0001,
        timestamp: "2021-09-01 12:00:00",
    },
    {
        id: 5,
        address: "0x757...16a0",
        amount: 0.0001,
        timestamp: "2021-09-01 12:00:00",
    },
    {
        id: 4,
        address: "0x757...16a0",
        amount: 0.0001,
        timestamp: "2021-09-01 12:00:00",
    },
    {
        id: 5,
        address: "0x757...16a0",
        amount: 0.0001,
        timestamp: "2021-09-01 12:00:00",
    },{
        id: 4,
        address: "0x757...16a0",
        amount: 0.0001,
        timestamp: "2021-09-01 12:00:00",
    },
    {
        id: 5,
        address: "0x757...16a0",
        amount: 0.0001,
        timestamp: "2021-09-01 12:00:00",
    },
  ],
});

const proofSlice = createSlice({
    name: 'Payment Proof',
    initialState: initialState(),
    reducers: {
        resetPaymentProof: state => initialState(),
        alterPaymentProof: (state, action) => {
            state.payments = action.payload.payments;
            
        },
    }
})

export const {
    resetPaymentProof,
    alterPaymentProof
    
   
} = proofSlice.actions

export default proofSlice.reducer