import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu } from '@mantine/core';
import { NavLink } from '../../node_modules/react-router-dom/dist/index';
import { useLocation } from '../../node_modules/react-router-dom/dist/index';


const Loading = (props) => {
    const location = useLocation(); // Added useLocation hook
    const loadingSlice = useSelector(state => state.mainapp.loadingSlice)


    return (

        <>
            {
                loadingSlice.loadingState == true ? <div className='loading-view'>
                    <div className='loading-inner'>
                        <img src='/assets/loading.gif' alt='logo'></img>
                        <div className='loading-text'>
                            <h1>{loadingSlice.loadingTitle}...</h1>
                            <p>{loadingSlice.loadingDescription}...</p>
                        </div>
                    </div>
                </div> : null
            }
        </>

    );

};

export default Loading;
