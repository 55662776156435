import React, { useEffect } from "react";
import { Link, Outlet, Route } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import { useState } from "react";
import { useDispatch } from "react-redux";


import { useNavigate } from "react-router-dom";


import { alterLoading } from "../store/schemas/loadingSlice";
import { setCurrentActiveChain } from "../store/schemas/chainSlice";


function SwapNetworkSolo() {
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const metamaskConnected = useSelector(state => state.mainapp.authSlice.metamask.connected)


    const currentSelectedChain = useSelector(state => state.mainapp.chainSlice.currentChain)
    const [selectedChain, setSelectedChain] = useState(currentSelectedChain.id || 137)
    const isOnChain = useSelector(state => state.mainapp.authSlice.metamask.isOnChain)

    const chainList = useSelector(state => state.mainapp.chainSlice.chainList);

    const expectedChainList = useSelector(state => state.mainapp.chainSlice.expectedChainList);



    const [networkAdded, setNetworkAdded] = useState(true);

    const addPolygonNetwork = async () => {
        const rpcUrl = chainList.find(chain => chain.id === selectedChain).rpcUrl
        const chainId = chainList.find(chain => chain.id === selectedChain).chainId
        const chainName = chainList.find(chain => chain.id === selectedChain).name
        const nativeCurrency = chainList.find(chain => chain.id === selectedChain).nativeCurrency
        try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                // set from selected network
                params: [{
                    chainId: '0x' + chainId.toString(16),
                    chainName: chainName,
                    nativeCurrency: nativeCurrency,
                    rpcUrls: [rpcUrl],

                }],


            });
            setNetworkAdded(true)

        } catch (error) {
            console.error("Failed to add network to MetaMask:", error);

        }
    };



    async function switchNetwork() {

        dispatch(alterLoading({
            loading: true,
            statusText: 'Switching to ' + chainList.find(chain => chain.id === selectedChain).name,
            origin: 'Network Manager'

        }))
        dispatch(
            setCurrentActiveChain(chainList.find(chain => chain.id === selectedChain))
        )
        setTimeout(() => {

            window.location.href =
                window.location.href.replace(currentSelectedChain.slug, chainList.find(chain => chain.id === selectedChain).slug)
        }, 1000)

    }



    const getIcon = (chainId) => {
        switch (chainId) {
            case 137:
                return '/assets/chains/polygon.png'
            case 1:
                return '/assets/chains/ethereum.png'
            case 56:
                return '/assets/chains/binance.png'
            case 43114:
                return '/assets/chains/avalanche.png'
            case 61:
                return '/assets/chains/ethereumclassic.png'
            case 42161:
                return '/assets/chains/arbitrum.png'
            case 10:
                return '/assets/chains/optimism.png'
            case 250:
                return '/assets/chains/fantom.png'
            default:
                return '/assets/chains/polygon.png'
        }
    }



    return (
        <>
            {
                networkAdded ?
                    <div className="auth-window lg-window vivify fadeIn duration-300 " >
                        {/* <div className="auth-header">
                        <div className='promo-action'>

                            <h1 style={{ marginBottom: 7 }}>
                                Wrong Network: Switch to Polygon
                            </h1>
                            <p>
                                Please switch MetaMask to Polygon network to continue.
                            </p>

                        </div>
                    </div> */}


                        <div className="chain-switcher">


                            <div className="chain-list">
                                {

                                    chainList.map((chain) => {

                                        return <button className={selectedChain === chain.id ? 'selected-chain chain-wrap' :
                                            chain.scan ? 'chain-wrap' : 'chain-wrap disabled-chain'
                                        } onClick={() => setSelectedChain(chain.id)}>
                                            <div className="chain-btn">
                                                <div className="chain-left">
                                                    <img alt="icon" src={
                                                        getIcon(chain.id)
                                                    } />
                                                    <div className="chain-text">
                                                        {
                                                            chain.id === 137 ? <span>
                                                                Recommended
                                                            </span> : ''
                                                        }
                                                        <h3>{chain.name}</h3>

                                                    </div>

                                                </div>
                                                <div className="chain-right">
                                                    {
                                                        selectedChain === chain.id ? <i className="material-icons" style={{
                                                            color: 'var(--main-color)'
                                                        }}>check_circle</i> : ''
                                                    }
                                                </div>
                                            </div>
                                        </button>
                                    })
                                }
                            </div>
                        </div>

                        <div className="meta-inline-container" style={{
                            margin: 'auto',

                        }}>
                            <button className="meta-btn" onClick={switchNetwork} >

                                Swap Network
                            </button>
                        </div>
                    </div> : <div className="auth-window " style={{
                        gridTemplateRows: '1fr ',
                        height: '430px'
                    }}>
                        <div className="auth-header">
                            <div className="floating-header">
                                <button className="back-btn" onClick={() => {
                                    setNetworkAdded(true)
                                }}><i className="material-icons">arrow_back</i>
                                    <span>Back</span>
                                </button>
                            </div>
                            <div className='promo-action'>
                                <img src={
                                    getIcon(selectedChain)
                                } alt="icon" />

                                <h1 style={{ marginBottom: 7 }}>
                                    Add {
                                        chainList.find(chain => chain.id === selectedChain).name
                                    } Network
                                </h1>
                                <p>
                                    Click to add {chainList.find(chain => chain.id === selectedChain).name} network to MetaMask and use with Frigid {(chainList.find(chain => chain.id === selectedChain).rpcUrl).toString()}
                                </p>

                            </div>
                        </div>

                        <div style={{
                            margin: 'auto',
                            marginBottom: 35
                        }}>
                            <button className="meta-btn" onClick={addPolygonNetwork} >
                                <i className="material-icons">add_circle</i>

                                Add Network
                            </button>
                        </div>


                    </div>
            }
            <style>
                {
                    `
                    
.mantine-Popover-dropdown {
    border: none;
    background-color: transparent;
}
                    `
                }
            </style>
        </>


    );
}

export default SwapNetworkSolo;

