import { createAsyncThunk } from '@reduxjs/toolkit'
import { alterBlockTime, alterEpoch, alterFaucetTime, alterIndex, alterStakeholderNotWaitList, alterStakingEarnings, alterStakingStatus, alterStakingTime, alterTaxReward, alterUnstakeLimits } from '../store/schemas/stakingSlice'
import Web3 from 'web3'
import { updateMetaAuthHappening } from '../store/schemas/authManagementSlice'
import { alterNotification } from '../store/schemas/notificationSlice'
import { alterNewsLetter } from '../store/schemas/newsSlice'
import { ethers, parseUnits, formatUnits, Contract, hexlify, toUtf8Bytes, isAddress } from "ethers";
import { dexie } from '../helper_functions/dexie'
import { startLoading, stopLoading } from '../store/schemas/loadingSlice'
import { getNetworkTokens } from '../helpers/helper'
import JSONBig from 'json-bigint';



export const manageNetwork = createAsyncThunk(
  'web3/manageNetwork',
  async (data, thunkApi) => {
    try {
      let chainId = process.env.REACT_APP_NETWORK
      window.ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }],
        })
        .catch((switchError) => {
          console.log(switchError)
          if (switchError.code === 4902) {
              switch (process.env.REACT_APP_ORIGIN) {

                case 'eth.pluricoin.io':
                  window.ethereum
                    .request({
                      method: 'wallet_addEthereumChain',
                      params: [
                        {
                          chainId: '0x1', // 56 in decimal, which is the chain ID for Binance Smart Chain
                          chainName: 'Ethereum Smart Chain Mainnet',
                          nativeCurrency: {
                            name: 'Ethereum',
                            symbol: 'ETH', // BNB is the native currency of Binance Smart Chain
                            decimals: 18,
                          },
                          rpcUrls: ['https://eth.llamarpc.com	'], // Update this to your matic RPC URL environment variable
                        },
                      ],
                    })
                    .catch((addError) => {
                      if(addError.code == 4001){
                        thunkApi.dispatch(alterNotification({ position: 'bottom-left', alertStyle: 'error', messageTitle: "Incorrect Network", message: "Cannot access features unless connected to the recommended network.", visible: true }))
                       }
                    });
                  break;

                case 'polygon.pluricoin.io':
                  window.ethereum
                    .request({
                      method: 'wallet_addEthereumChain',
                      params: [
                        {
                          chainId: '0x89', 
                          chainName: 'Polygon Mainnet',
                          nativeCurrency: {
                            name: 'Matic',
                            symbol: 'MATIC', 
                            decimals: 18,
                          },
                          rpcUrls: ['https://polygon-rpc.com'], 
                          
                        },
                      ],
                    })
                    .catch((addError) => {
                     if(addError.code == 4001){
                      thunkApi.dispatch(alterNotification({ position: 'bottom-left', alertStyle: 'error', messageTitle: "Incorrect Network", message: "Cannot access features unless connected to the recommended network.", visible: true }))
                     }
                    });
                  break;

                  case 'localhost':
                  window.ethereum
                    .request({
                      method: 'wallet_addEthereumChain',
                      params: [
                        {
                          chainId,
                          chainName: 'Ganache',
                          nativeCurrency: {
                            name: 'Ethereum',
                            symbol: 'ETH',
                            decimals: 18,
                          },
                          rpcUrls: [process.env.REACT_APP_RPC],
                        },
                      ],
                    })
                    .catch((addError) => {
                      if(addError.code == 4001){
                        thunkApi.dispatch(alterNotification({ position: 'bottom-left', alertStyle: 'error', messageTitle: "Incorrect Network", message: "Cannot access features unless connected to the recommended network.", visible: true }))
                       }
                    });
                  break;
                default:
                  break;
              

            }

          }
          else if (switchError.code == 4001) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', alertStyle: 'error', messageTitle: "Incorrect Network", message: "Cannot access features unless connected to the recommended network.", visible: true }))
          }

        });

    } catch (error) {
      console.log(error)
    }
  }
)


export const stake = createAsyncThunk(
  'web3/staking',
  async (data, thunkApi) => {
    try {
      const state = thunkApi.getState()
      const account = state.auth.metamask.accountId
      const currentChain = state.mainapp.chainSlice.currentChain
      const {stakeholder, pluri} = getNetworkTokens[currentChain.slug]

      const allowance = await pluri.contract.allowance(account, stakeholder.token.address)
      console.log(allowance)
      if (allowance != 21000000000000000000000n) {
        console.log("HI")
        const tx = await  pluri.contract.approve(stakeholder.token.address, 21000000000000000000000n)
        thunkApi.dispatch(startLoading({ title: 'Processing Transaction', description: 'Please wait for the blockchain to approve your transaction' }))

        const f = await tx.wait()
        await new Promise(resolve => setTimeout(resolve, 3000)); // Wait for 5 seconds
        thunkApi.dispatch(stopLoading())
      }
     
      const newTx = await stakeholder.contract.stake()
      thunkApi.dispatch(startLoading({ title: 'Processing Transaction', description: 'Please wait for the blockchain to approve your transaction' }))
      await newTx.wait()
      await new Promise(resolve => setTimeout(resolve, 3000)); 
      thunkApi.dispatch(stopLoading())
      thunkApi.dispatch(isStaked())
      thunkApi.dispatch(stopLoading())
    } catch (error) {
      console.log(error)
      thunkApi.dispatch(stopLoading())
      thunkApi.dispatch(alterNotification({ position: 'bottom-left', alertStyle: 'error', messageTitle: "Staking Error", message: error.reason, visible: true }))
    }
  }
)


export const postUserArticle = createAsyncThunk(
  'web3/postUserArticle',
  async (data, thunkApi) => {
    try {

      // const { httpWeb3, httpContractPluri } = await createWeb3Instance()

      const state = thunkApi.getState()
      const account = state.auth.metamask.accountId
      const currentChain = state.mainapp.chainSlice.currentChain
      const {pluri} = getNetworkTokens[currentChain.slug]

      data.creation_time = new Date()
      let json = hexlify(toUtf8Bytes(JSON.stringify(data)));
      const balance = await pluri.contract.balanceOf(account)
      if(balance < 1100000000000n){
        thunkApi.dispatch(alterNotification({ position: 'bottom-left', alertStyle: 'error', messageTitle: "Insufficient Balance", message: "You need at least 0.0000011 PLURI to submit an article.", visible: true }))
        return
      }

      const txResponse = await pluri.contract.transferWithData(process.env.REACT_APP_SITE_WEB3_ADDRESS, 1000000000000n, json)
      thunkApi.dispatch(startLoading({ title: 'Submitting Article', description: 'Publishing to the blockchain' }))

      const results = await txResponse.wait()
      dexie.posts.add({ title: data.title, sub_title: data.sub_title, image: data.image, creation_time: data.creation_time, hash: results.hash, network: currentChain.slug })
      thunkApi.dispatch(stopLoading())
      thunkApi.dispatch(alterNotification({ position: 'bottom-left', alertStyle: 'success', messageTitle: "Article Submitted", message: "Your article is now on the blockchain.", visible: true }))
      return 'success'
    } catch (error) {
      thunkApi.dispatch(stopLoading())
      console.log(error)
    }

  }
)

export const isStaked = createAsyncThunk(
  'web3/isStaked',
  async (data, thunkApi) => {
    try {
      const state = thunkApi.getState()
      const account = state.auth.metamask.accountId
      const currentChain = state.mainapp.chainSlice.currentChain
      if(getNetworkTokens[currentChain.slug]){
        const {stakeholder} = getNetworkTokens[currentChain.slug]
        
        if(account == null){
          return
        }

        let info = await stakeholder.rpcContract.stakeholdersInfo(account)
        let user_stake = JSONBig.parse(JSONBig.stringify(info))
        user_stake.stakedAt = user_stake[0]
        user_stake.index = user_stake[1]
        user_stake.earnings = user_stake[2]

        if (user_stake[0] == 0n) {
          thunkApi.dispatch(alterStakingStatus(false))
        }
        else {
          const user_earnings = user_stake[2].toString().padEnd(19, 0)
          const epochStatus = await stakeholder.rpcContract.epochInitialized()
          let taxAmount = await stakeholder.rpcContract.taxAmount()
          taxAmount = formatUnits(taxAmount.toString(), 'ether')
          const unstakeCounter = await stakeholder.rpcContract.unstakeCounter()
          const currentUnstakeLimit = await stakeholder.rpcContract.currentUnstakeLimit()
          const lastContainerTimestamp = await stakeholder.rpcContract.lastContainerTimestamp()

          if (epochStatus) {
            const inEpoch = await stakeholder.rpcContract.getCurrentEpochStakeholders()
            const approve = inEpoch.some((item) => item[0].toLowerCase() == account.toLowerCase())
            const epochItem = await stakeholder.rpcContract.currentEpoch()
            let epoch  = JSONBig.parse(JSONBig.stringify(epochItem))
            epoch.epochSize = epoch[1]
            epoch.currentIndex = epoch[0]
            if (approve) {
              thunkApi.dispatch(alterEpoch({ size: parseInt(epoch.epochSize.toString()), index: parseInt(epoch.currentIndex.toString()) , start:true }))
              thunkApi.dispatch(alterStakeholderNotWaitList(true))

            }
            else {
              thunkApi.dispatch(alterEpoch({ size: parseInt(epoch.epochSize.toString()), index: parseInt(epoch.currentIndex.toString()), start:true }))
              thunkApi.dispatch(alterStakeholderNotWaitList(false))
            }
          }
          else{
            const stakeholders = await stakeholder.rpcContract.getCurrentListOfStakeholders()
            thunkApi.dispatch(alterEpoch({ size:Object.keys(stakeholders).length, index: 0, start:false }))
            thunkApi.dispatch(alterStakeholderNotWaitList(false))
          }

          thunkApi.dispatch(alterStakingStatus(true))
          thunkApi.dispatch(alterStakingEarnings(user_earnings.slice(0, -18)))
          thunkApi.dispatch(alterIndex(parseInt(user_stake.index.toString())))
          let date = new Date(Number(user_stake.stakedAt) * 1000)
          date.setDate(date.getDate() + 30);
          thunkApi.dispatch(alterStakingTime(date.toString()))

          let newContainerTime = new Date(Number(lastContainerTimestamp) * 1000)
          newContainerTime.setDate(newContainerTime.getDate() + 30);
          thunkApi.dispatch(alterTaxReward(taxAmount))
          thunkApi.dispatch(alterUnstakeLimits({ count: parseInt(unstakeCounter), limit: parseInt(currentUnstakeLimit), lastContainerTimestamp:newContainerTime.toString() }))

        }
      }
     
      

    } catch (error) {
      console.log(error)
    }
  }
)



export const claimFaucet = createAsyncThunk(
  'web3/claimFaucet',
  async (data, thunkApi) => {

    try {
        const state = thunkApi.getState()
        const account = state.auth.metamask.accountId
        const currentChain = state.mainapp.chainSlice.currentChain
        const {faucet} = getNetworkTokens[currentChain.slug]

        if (!isAddress(data.address)) {
          thunkApi.dispatch(alterNotification({ position: 'bottom-left', alertStyle: 'error', messageTitle: "Address Invalid", message: 'The given address is not valid', visible: true }))
          return
        }

        const tx = await faucet.contract.callAutoMint(data.address)

        thunkApi.dispatch(startLoading({ title: 'Processing Claim', description: 'Please wait for the blockchain to approve your transaction' }))
        const f = await tx.wait()
        thunkApi.dispatch(stopLoading())

        thunkApi.dispatch(alterNotification({ position: 'bottom-left', alertStyle: 'success', messageTitle: "Claim Complete", message: "You have successfully claimed your PLURI. PLURI will be automatically deposited to your wallet.", visible: true }))
      

    } catch (error) {
      console.log(error)
      thunkApi.dispatch(stopLoading())
      let messageTitle = "Pluri Error"
      let message = error?.info?.error?.data?.data?.reason
      if(message == undefined){
        message = error?.reason
      }
      thunkApi.dispatch(alterNotification({ position: 'bottom-left', alertStyle: 'error', messageTitle: messageTitle, message: message, visible: true }))
    }
  }
)

export const unstake = createAsyncThunk(
  'web3/unstake',
  async (data, thunkApi) => {
    try {
        const state = thunkApi.getState()
        const account = state.auth.metamask.accountId
        const currentChain = state.mainapp.chainSlice.currentChain
        const {stakeholder} = getNetworkTokens[currentChain.slug]

    
        const tx = await stakeholder.contract.unstake()

        thunkApi.dispatch(startLoading({ title: 'Unstaking In Progress', description: 'Please wait for the blockchain to approve your transaction' }))

        const f = await tx.wait()
        thunkApi.dispatch(stopLoading())
        thunkApi.dispatch(alterNotification({ position: 'bottom-left', alertStyle: 'success', messageTitle: "Unstake Complete", message: "You have successfully unstaked your PLURI. You can now withdraw your PLURI from the staking contract.", visible: true }))
      

    } catch (error) {
      thunkApi.dispatch(stopLoading())
      thunkApi.dispatch(alterNotification({ position: 'bottom-left', alertStyle: 'error', messageTitle: "Metamask", message: error.reason, visible: true }))
    }
  }
)



export const getFaucet = createAsyncThunk(
  'web3/getFaucet',
  async (data, thunkApi) => {
    try {
      const state = thunkApi.getState()
      const currentChain = state.mainapp.chainSlice.currentChain

      const {stakeholder} = getNetworkTokens[currentChain.slug]
      const epochStatus = await stakeholder.rpcContract.epochInitialized()
      if (epochStatus) {
        const epochItem = await stakeholder.rpcContract.currentEpoch()
        let epoch  = JSONBig.parse(JSONBig.stringify(epochItem))
        epoch.epochSize = epoch[1]
        epoch.currentIndex = epoch[0]
        thunkApi.dispatch(alterEpoch({ size: parseInt(epoch.epochSize.toString()), index: parseInt(epoch.currentIndex.toString()) }))
        const lastClaim = await stakeholder.rpcContract.lastAutoMintTimestamp()
        const date = new Date(Number(lastClaim) * 1000)
        thunkApi.dispatch(alterFaucetTime(date.toString()))
       
        const block = await  stakeholder.rpcProvider.getBlockNumber()
        const blockTime = await stakeholder.rpcProvider.getBlock(block);
        const newBlockDate = new Date(Number(blockTime.timestamp) * 1000)
        thunkApi.dispatch(alterBlockTime(newBlockDate.toString()))
      }

      

      return 'success'
    } catch (error) {
      console.log(error)
    }
  }
)


export const getNewsletters = createAsyncThunk(
  'web3/getNewsletters',
  async (data, thunkApi) => {
    try {
      const { web3, pluriContract, stakeContract, faucetContract } = await createWeb3Instance()
      const currentChain = await web3.eth.getChainId();
      if (currentChain != process.env.REACT_APP_NETWORK) {
        return false
      }
      const accounts = await web3.eth.getAccounts()
      if (accounts.length != 0) {
        const account = accounts[0]
        const results = await web3.eth.getTransactionReceipt('0xe9cf6a641f9e1bb477eef6cc3ed12e418e18b64597fe910433e2204540d033a5')
        var decodedLog = await web3.utils.hexToUtf8(results.logs[1].data);
        let regexStart = /^.*?{/;
        let regexEnd = /}[^}]*$/;
        let result = JSON.parse((decodedLog.replace(regexStart, '{').replace(regexEnd, '}')));
        result.transactionHash = '0xe9cf6a641f9e1bb477eef6cc3ed12e418e18b64597fe910433e2204540d033a5'
        const array = [result]
        await thunkApi.dispatch(alterNewsLetter(array))
        return array
      }
    } catch (error) {

    }

  }
)