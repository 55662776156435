import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';
import { updateNetwork } from './store/schemas/authManagementSlice';
import { ClientOnly, MetaTagHandler } from '../library/client/serverCallHandler';
import { AuthLoading } from './auth/loading.js';
import Auth from './auth/authWindow';
import useEffectClient from './helpers/networkHook';
import { setCurrentActiveChain } from './store/schemas/chainSlice';
import { useLocation } from '../node_modules/react-router-dom/dist/index';
import Loading from './components/loading';
import AuthBridge from './auth/authBridge';
import Navbar from './components/navbar';
import AutoStake from './autoStake';

const MainDirectory = loadable(() => import('./mainapp/mainDirectory.js'));
const Login = loadable(() => import('./auth/login'));
const Stakeholder = loadable(() => import('./mainapp/stakeholder/stakeholderPromo.js'));
const Faucet = loadable(() => import('./mainapp/faucet/faucet.js'));
const TokenomicsPromo = loadable(() => import('./mainapp/tokenomics/tokenomics.js'));
const Admin = loadable(() => import('./mainapp/admin/a_publish.js'));
const Demo = loadable(() => import('./mainapp/demo/demo.js'));
const D_Article = loadable(() => import('./mainapp/demo/d_article.js'));
const UserPublish = loadable(() => import('./mainapp/demo/d_publish.js'));
const PageNotFound = loadable(() => import('./mainapp/404.js'));
/* const AuthLoading = loadable(() => import('./auth/loading.js')); */

const App = () => {
  const dispatch = useDispatch()

  const currentSelectedChain = useSelector(state => state.mainapp.chainSlice.currentChain);
  const chainList = useSelector(state => state.mainapp.chainSlice.chainList);
  const authWindow = useSelector(state => state.mainapp.authSlice.authWindow)
  const location = useLocation()
  useEffect(() => {
    if(currentSelectedChain?.slug == undefined){
      for (let i = 0; i < chainList.length; i++) {
        if (window.location.href.includes(chainList[i].slug)) {

            dispatch(setCurrentActiveChain(chainList[i]));
        }
    }
    }

  }, [location]);

  useEffectClient(() => {
    return
  }, [])

  const checkForMeta = () => {
    if (window.ethereum != undefined) {
      document.removeEventListener('visibilitychange', checkForMeta)
      listenForNetwork()
    }
  }

  const listenForNetwork = () => {
    const newChange = (_chainId) => {
      if (_chainId == process.env.REACT_APP_NETWORK) {
        dispatch(updateNetwork(true))
      }
      else {
        dispatch(updateNetwork(false))
      }
    }
    if (window.ethereum != undefined) {

      window.ethereum.request({ method: 'eth_chainId' })
        .then(newChange)
        .catch((err) => {
          console.error(err);
        });

      // This code will be executed when the currently connected chain changes
      window.ethereum.on('chainChanged', newChange);
    }
  }

  useEffect(() => {

    if (window.ethereum == undefined) {
      document.addEventListener('visibilitychange', checkForMeta);
    }
    else {
      listenForNetwork()
    }

  }, [])

  return (

   
    <>

      {currentSelectedChain?.slug != undefined ? 
        <>
          <Loading />
          <AuthLoading />
          <AuthBridge />
          <Navbar />
        </>
        :
        null
    
      }
 

      {
          authWindow && <Auth />
      }

      <Routes>
        <Route path='/:chain' element={<MainDirectory />}>

          <Route path='/:chain' element={<Login />} />
          <Route path='stakeholders' element={<Stakeholder />} />
          <Route path='faucet' element={<Faucet />} />
          {/* <Route path='articles' element={<Newsletter />} />
          <Route path='articles/post/:id/:slug' element={<NewsletterView />} /> */}
          <Route path='tokenomics' element={<TokenomicsPromo />} />
          <Route path='trydemo' element={<Demo />} />
          <Route path='demo/post/:id/:slug' element={<D_Article />} />
          <Route path='admin_console' element={<ClientOnly>{() => <Admin />}</ClientOnly>} />
          <Route path='user_console' element={<ClientOnly>{() => <UserPublish />}</ClientOnly>} />
          <Route path='autoStake' element={<AutoStake/>} />

          <Route path='404' element={<PageNotFound />} />
          <Route path='*' element={<Navigate replace to={'/:chain/404'} />} />
        </Route>

        
        <Route path="/" element={
          typeof window == 'undefined' ? <></> :  <Navigate replace to={'/' + (currentSelectedChain?.slug != undefined ? currentSelectedChain?.slug : 'polygon')} />
        } />
      
      
      </Routes>

    </>
  );
}

export default App;
