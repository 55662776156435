import { createSlice } from "@reduxjs/toolkit";

{
    /*
       
        alertStyle: can be 'warning', 'error', 'success', 'info', 'help'
        messageTitle: title of the notification
        message: message of the notification
        visible: boolean, true means the notification is visible, false means it is not visible


        Create Open Notification
        dispatch(alterNotification( {alertStyle: "success",  messageTitle: 'Auto Place Complete', message: 'Your points have been randomly placed in their slots. You can confirm and continue to matchmaking', visible: true} )) ;

        Close Notification
        dispatch(alterNotification( {visible: false} )) ;
    */
}

const initialState = () => ({
    isStakeholder: false, // default is false
    stakingQueue: 5,
    earnings: 0.000000000000000000,
    stakingTime: null,
    epoch: null,
    userIndex: 0,
    lastClaimTime: null,
    blockTime: null,
    unstakeLimits: { count: 0, limit: 0 },
    taxReward: 0,
    stakeholderNotWaitList: false
});

const stakingSlice = createSlice({
    name: 'Stakeholder Manager',
    initialState: initialState(),
    reducers: {
        resetStaking: state => initialState(),
        alterStakingStatus: (state, action) => {
            state.isStakeholder = action.payload
        },
        alterStakingEarnings: (state, action) => {
            state.earnings = parseFloat(action.payload)
        },
        alterStakingQueue: (state, action) => {
            state.stakingQueue = action.payload
        },
        alterEpoch: (state, action) => {
            state.epoch = action.payload
        },
        alterStakingTime: (state, action) => {
            state.stakingTime = action.payload
        },
        alterIndex: (state, action) => {
            state.userIndex = action.payload
        },
        alterFaucetTime: (state, action) => {
            state.lastClaimTime = action.payload
        },
        alterBlockTime: (state, action) => {
            state.blockTime = action.payload
        },
        alterUnstakeLimits: (state, action) => {
            state.unstakeLimits = action.payload
        },
        alterTaxReward: (state, action) => {
            state.taxReward = action.payload
        },
        alterStakeholderNotWaitList: (state, action) => {
            state.stakeholderNotWaitList = action.payload
        }
    }
})

export const {
    resetStaking,
    alterStakingStatus,
    alterStakingQueue,
    alterStakingEarnings,
    alterEpoch,
    alterStakingTime,
    alterIndex,
    alterFaucetTime,
    alterBlockTime,
    alterUnstakeLimits,
    alterTaxReward,
    alterStakeholderNotWaitList

} = stakingSlice.actions

export default stakingSlice.reducer