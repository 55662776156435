import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    userActive: false,
    authWindow: false,
    metamask: {
        connected: true,
        metaText: 'Connect with MetaMask',
        isOnChain: true,
        permissionState: true,
        accountId: null,
        reCheck: false,
        chainMatch: false
    }
})


const authSlice = createSlice({
    name: 'user_auth',
    initialState: initialState(),
    reducers: {
        resetAuth: state => initialState(),
        isLogin: (state) => {
            state.userActive = true
        },
        isNotLogin: (state) => {
            state.userActive = false
        },
        setMetamaskConnected: (state, action) => {
            state.metamask.connected = action.payload
        },
        setMetaText: (state, action) => {
            state.metamask.metaText = action.payload
        },

        setPermissionState: (state, action) => {
            state.metamask.permissionState = action.payload
        },
        setOnChain: (state, action) => {
            state.metamask.isOnChain = action.payload
        },
        setAccountId: (state, action) => {
            state.metamask.accountId = action.payload
        },
        setRecheck: (state, action) => {
            state.metamask.reCheck = state.metamask.reCheck ? false : true
        },
        toggleAuthWindow: (state) => {
            state.authWindow = state.authWindow ? false : true
        },
        setChainMatch: (state, action) => {
            state.metamask.chainMatch = action.payload
        }
    }
})

export const { resetAuth, isLogin, isNotLogin, setMetamaskConnected, setMetaText, setPermissionState, setOnChain, setAccountId, setRecheck, toggleAuthWindow, setChainMatch } = authSlice.actions

export default authSlice.reducer